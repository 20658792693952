import React from "react"
import { Col, Row } from "reactstrap"
import { Link } from "gatsby"
import logo from "../../images/foretheta-logo-white.png"
import fb from "../../images/facebook-logo.png"
import linkedin from "../../images/linkedin-logo.png"
import git from "../../images/github-logo.png"
import twitter from "../../images/twitter-logo.png"
import medium from "../../images/medium-logo.png"

/**
|--------------------------------------------------
| @Noman comment some footer link items...
| Uncomment them later when we have pages developed
| and assign them their corresponding routes.
| Because right now It's causing errors in console => "to" prop is required
|--------------------------------------------------
*/

const Footer = () => {
  return (
    <div className="footer-container">
      <ul className="mt-4">
        <li>
          <Link to="/">
            <img src={logo} alt="Foretheta" />
          </Link>
        </li>
        <li>
          <p className="mt-2">Secure Data Engineering</p>
        </li>
      </ul>
      <ul className="mt-4">
        <li className="font-weight-bold mb-3">Services</li>
        <li>
          <Link to="/data-engineering">Data Engineering</Link>
        </li>
        <li>
          <Link to="/web-app">Web Applications</Link>
        </li>
        <li>
          <Link to="/app-security">Application Security</Link>
        </li>
      </ul>
      <ul className="mt-4">
        <li className="font-weight-bold mb-3">Products</li>
        <li>
          <Link to="/billgist">Billgist</Link>
        </li>
        <li>
          <Link to="/traindex">Traindex</Link>
        </li>
      </ul>
      <ul className="mt-4">
        <li className="font-weight-bold mb-3">Resources</li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/our-work">Case Studies</Link>
        </li>
        <li>
          <Link to="/process">Our Processes</Link>
        </li>
      </ul>
      <ul className="mt-4">
        <li className="font-weight-bold mb-3">Company</li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/careers">Careers</Link>
        </li>
        <li>
          <Link to="/contact">Contact Us</Link>
        </li>
      </ul>
      <ul className="mt-4 last-column">
        <li className="font-weight-bold mb-3">Stay in touch</li>
        <li className="mb-2">
          <a href="https://github.com/foretheta/" className="mr-2" target="_blank" rel="noopener noreferrer">
            <img src={git} alt="Foretheta Github" />
          </a>
          <a href="https://medium.com/foretheta" className="mr-2" target="_blank" rel="noopener noreferrer">
            <img src={medium} alt="Forethea Medium" />
          </a>
          <a
            href="https://www.linkedin.com/company/foretheta/"
            className="mr-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={linkedin} alt="Foretheta Linkedin" />
          </a>
          <a href="https://twitter.com/foretheta" className="mr-2" target="_blank" rel="noopener noreferrer">
            <img src={twitter} alt="Foretheta Twitter" />
          </a>
          <a href="https://www.facebook.com/Foretheta/" className="mr-2" target="_blank" rel="noopener noreferrer">
            <img src={fb} alt="Foretheta Facebook" />
          </a>
        </li>
        <li>
          <a href="mailto:help@foretheta.com">help@foretheta.com</a>
        </li>
        <li>
          <a href="mailto:recruiting@foretheta.com">recruiting@foretheta.com</a>
        </li>
      </ul>
    </div>
  )
}

export default Footer
