import React from "react"
import logo from "../../../images/foretheta-logo.png"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap"
import ReactGA from "react-ga"
import Link from "gatsby-link"
import "./header.css"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = { isOpen: false }
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      ReactGA.initialize(process.env.GATSBY_GOOGLE_ANALYTICS)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render() {
    const { isOpen } = this.state
    return (
      <Navbar light expand="md" className="mx-lg-3">
        <NavbarBrand className="pl-lg-4" href="/">
          <img src={logo} alt="Foretheta" className="header-logo pl-2" />
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} className="mr-3" />
        <Collapse isOpen={isOpen} navbar id="nav">
          <Nav className="ml-auto text-danger" navbar>
            <NavItem className="pr-lg-4">
              <Link className="nav-link" to="/">
                Home
              </Link>
            </NavItem>
            <UncontrolledDropdown className="pr-lg-4" nav inNavbar>
              <DropdownToggle nav>
                Services <i className="fa fa-angle-down" aria-hidden="true" />
              </DropdownToggle>
              <DropdownMenu left="true">
                <DropdownItem className="dropdown-item-link" tag={Link} to="/data-engineering">
                  Data Engineering
                </DropdownItem>
                <DropdownItem className="dropdown-item-link" tag={Link} to="/web-app">
                  Web Applications
                </DropdownItem>
                <DropdownItem className="dropdown-item-link" tag={Link} to="/app-security">
                  Application Security
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown className="pr-lg-4" nav inNavbar>
              <DropdownToggle nav>
                Products <i className="fa fa-angle-down" aria-hidden="true" />
              </DropdownToggle>
              <DropdownMenu left="true">
                <DropdownItem className="dropdown-item-link" tag={Link} to="/billgist">
                  Billgist
                </DropdownItem>
                <DropdownItem className="dropdown-item-link" tag={Link} to="/traindex">
                  Traindex
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown className="pr-lg-4" nav inNavbar>
              <DropdownToggle nav>
                Resources
                <i className="fa fa-angle-down" aria-hidden="true" />
              </DropdownToggle>
              <DropdownMenu left="true">
                <DropdownItem className="dropdown-item-link" tag={Link} to="/blog">
                  Blog
                </DropdownItem>
                <DropdownItem className="dropdown-item-link" tag={Link} to="/our-work">
                  Case Studies
                </DropdownItem>
                <DropdownItem className="dropdown-item-link" tag={Link} to="/process">
                  Our Processes
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

            <UncontrolledDropdown className="pr-lg-4" nav inNavbar>
              <DropdownToggle nav>
                Company <i className="fa fa-angle-down" aria-hidden="true" />
              </DropdownToggle>
              <DropdownMenu left="true">
                <DropdownItem className="dropdown-item-link" tag={Link} to="/about">
                  About
                </DropdownItem>
                <DropdownItem className="dropdown-item-link" tag={Link} to="/careers">
                  Careers
                </DropdownItem>
                <DropdownItem className="dropdown-item-link" tag={Link} to="/contact">
                  Contact Us
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    )
  }
}

export default Header
