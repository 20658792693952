import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import Header from "./template/Header"
import Footer from "./template/footer"
import "../scss/app.scss"

const hostname = typeof window !== "undefined" ? window.location.origin : ""

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet>
      <meta
        name="description"
        content="We take our vision from concept to reality, and 
            help your company grow through the process"
      />
      <meta property="og:url" content="https://foretheta.com" />
      <meta property="og:title" content="Foretheta | Secure Data Engineering" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@foretheta" />
      <meta name="twitter:creator" content="@foretheta" />
      <meta name="twitter:url" content="https://foretheta.com" />
      <meta name="twitter:title" content="Foretheta | Secure Data Engineering" />
      <meta property="og:image" content="https://foretheta.com/OG_Tag.png" />
      <meta
        name="keywords"
        content="Data Engineering, Data Science Consulting, Web Application Development, 
            Application Security, Big Data, DevOps Engineering, UI/UX design, Software 
            Testing, Business Analysis, Penetration Testing, BillGist, Traindex, 
            Engineering consultant, Vulnerability assessment, Security Engineering, 
            Security Analysis, QA & Testing, Project Management, Product Management, AI, 
            Artificial Intelligence, Data Engineering, AWS Consulting, Data analytics, 
            Data infrastructure, Secure Data Engineering"
      />
      <link href="https://fonts.googleapis.com/css?family=Montserrat" rel="stylesheet" />
      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
    </Helmet>

    <Header />
    {children}
    <Footer />
  </div>
)

TemplateWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}

export default TemplateWrapper
